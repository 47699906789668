import React from "react";
import banner from "./banner.jpg";
import styled from "styled-components";
import Layout from "components/Layout";

const Element = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  background: url(${banner}) top center no-repeat padding-box fixed content-box;
  background-size: cover;
  z-index: -1;
`;

const Header = styled.div`
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 350px;
  border: 1px solid pink;
  padding: 2rem;
  text-align: center;
`;

const Title = styled.div`
  font-size: 2.5rem;
  margin-bottom: 1rem;
`;

const SubTitle = styled.div`
  font-size: 1rem;
`;

const IndexPage = () => (
  <Layout>
    <Element>
      <Header>
        <Title>HILGERDUO</Title>
        <SubTitle>VIOLINE & VIOLONCELLO</SubTitle>
      </Header>
    </Element>
  </Layout>
);

export default IndexPage;
